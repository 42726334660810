<template>
  <v-app>
    <v-toolbar dark>
      <v-toolbar-title class="headline text-uppercase">
        <span>Vuetify</span>
        <span class="font-weight-light">Meterial Design</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn flat herf="#" target="_blank">
        <span class="mr-2">Latest Release</span>
      </v-btn>
    </v-toolbar>
    <v-main>
      <router-view/>
    </v-main>
    <v-bottom-navigation>
      <v-btn value="recent">
        <v-icon>mdi-history</v-icon>

        <span>Recent</span>
      </v-btn>

      <v-btn value="favorites">
        <v-icon>mdi-heart</v-icon>

        <span>Favorites</span>
      </v-btn>

      <v-btn value="nearby">
        <v-icon>mdi-map-marker</v-icon>

        <span>Nearby</span>
      </v-btn>
    </v-bottom-navigation>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
}
</script>
