import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import { loadFonts } from './plugins/webfontloader'

//axios.defaults.baseURL = 'http://127.0.0.1:8000/'
axios.defaults.baseURL = 'https://api.solartest.page/'

loadFonts()

createApp(App)
  .use(router)
  .use(vuetify)
  .use(router,axios)
  .mount('#app')

