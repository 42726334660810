<template>
  <v-container>
<h1>Welcome to the new world!!!?</h1>
  </v-container>
</template>

<script>

export default {
  name: 'HelloWorld',

  data: () => ({
  }),
}
</script>
