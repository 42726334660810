<template>
  <div>
    Show Data
    <!-- 显示数据的地方 -->
    <ul>
      <li v-for="item in items" :key="item.id">{{ item.itemno }} - {{ item.itemdes }}</li>
    </ul>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ItemsList', // 将组件名称改为多个单词
  data() {
    return {
      items: []  // 用于存储从API获取的数据
    };
  },
  mounted() {
    // 在组件挂载后立即调用API
    this.fetchItems();
  },
  methods: {
    fetchItems() {
      // 使用axios发送GET请求到Django API的URL
      //axios.get('https://spbackend-hvgio2nhhq-uc.a.run.app/showitemapi')
      axios.get('/showitemapi')
        .then(response => {
          // 请求成功后，将数据存储在组件的items数据中
          this.items = response.data;
        })
        .catch(error => {
          console.error('API请求错误:', error);
        });
    }
  }
};
</script>
